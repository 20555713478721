/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function n() {
  return [0, 0, 0];
}
function t(n) {
  return [n[0], n[1], n[2]];
}
function r(n, t, r) {
  return [n, t, r];
}
function e(t, r = n()) {
  const e = Math.min(3, t.length);
  for (let n = 0; n < e; ++n) r[n] = t[n];
  return r;
}
function u(n, t) {
  return new Float64Array(n, t, 3);
}
function o() {
  return n();
}
function c() {
  return r(1, 1, 1);
}
function i() {
  return r(1, 0, 0);
}
function f() {
  return r(0, 1, 0);
}
function a() {
  return r(0, 0, 1);
}
const l = o(),
  _ = c(),
  s = i(),
  m = f(),
  y = a(),
  N = Object.freeze(Object.defineProperty({
    __proto__: null,
    ONES: _,
    UNIT_X: s,
    UNIT_Y: m,
    UNIT_Z: y,
    ZEROS: l,
    clone: t,
    create: n,
    createView: u,
    fromArray: e,
    fromValues: r,
    ones: c,
    unitX: i,
    unitY: f,
    unitZ: a,
    zeros: o
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { _ as ONES, s as UNIT_X, m as UNIT_Y, y as UNIT_Z, l as ZEROS, t as clone, n as create, u as createView, e as fromArray, r as fromValues, c as ones, i as unitX, f as unitY, a as unitZ, N as v, o as zeros };