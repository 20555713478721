/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { create as t } from "../core/libs/gl-matrix-2/factories/vec3f64.js";
import { getEpsilon as n, RANDOM as a } from "../core/libs/gl-matrix-2/math/common.js";
function s(t) {
  const n = t[0],
    a = t[1],
    s = t[2];
  return Math.sqrt(n * n + a * a + s * s);
}
function r(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t;
}
function o(t, n, a, s) {
  return t[0] = n, t[1] = a, t[2] = s, t;
}
function u(t, n, a) {
  return t[0] = n[0] + a[0], t[1] = n[1] + a[1], t[2] = n[2] + a[2], t;
}
function e(t, n, a) {
  return t[0] = n[0] - a[0], t[1] = n[1] - a[1], t[2] = n[2] - a[2], t;
}
function c(t, n, a) {
  return t[0] = n[0] * a[0], t[1] = n[1] * a[1], t[2] = n[2] * a[2], t;
}
function i(t, n, a) {
  return t[0] = n[0] / a[0], t[1] = n[1] / a[1], t[2] = n[2] / a[2], t;
}
function h(t, n) {
  return t[0] = Math.ceil(n[0]), t[1] = Math.ceil(n[1]), t[2] = Math.ceil(n[2]), t;
}
function M(t, n) {
  return t[0] = Math.floor(n[0]), t[1] = Math.floor(n[1]), t[2] = Math.floor(n[2]), t;
}
function f(t, n) {
  return t[0] = Math.abs(n[0]), t[1] = Math.abs(n[1]), t[2] = Math.abs(n[2]), t;
}
function l(t, n) {
  return t[0] = Math.sign(n[0]), t[1] = Math.sign(n[1]), t[2] = Math.sign(n[2]), t;
}
function m(t, n, a) {
  return t[0] = Math.min(n[0], a[0]), t[1] = Math.min(n[1], a[1]), t[2] = Math.min(n[2], a[2]), t;
}
function b(t, n, a) {
  return t[0] = Math.max(n[0], a[0]), t[1] = Math.max(n[1], a[1]), t[2] = Math.max(n[2], a[2]), t;
}
function d(t, n) {
  return t[0] = Math.round(n[0]), t[1] = Math.round(n[1]), t[2] = Math.round(n[2]), t;
}
function g(t, n, a) {
  return t[0] = n[0] * a, t[1] = n[1] * a, t[2] = n[2] * a, t;
}
function q(t, n, a, s) {
  return t[0] = n[0] + a[0] * s, t[1] = n[1] + a[1] * s, t[2] = n[2] + a[2] * s, t;
}
function x(t, n) {
  const a = n[0] - t[0],
    s = n[1] - t[1],
    r = n[2] - t[2];
  return Math.sqrt(a * a + s * s + r * r);
}
function p(t, n) {
  const a = n[0] - t[0],
    s = n[1] - t[1],
    r = n[2] - t[2];
  return a * a + s * s + r * r;
}
function v(t) {
  const n = t[0],
    a = t[1],
    s = t[2];
  return n * n + a * a + s * s;
}
function j(t, n) {
  return t[0] = -n[0], t[1] = -n[1], t[2] = -n[2], t;
}
function y(t, n) {
  return t[0] = 1 / n[0], t[1] = 1 / n[1], t[2] = 1 / n[2], t;
}
function z(t, n) {
  const a = n[0],
    s = n[1],
    r = n[2];
  let o = a * a + s * s + r * r;
  return o > 0 && (o = 1 / Math.sqrt(o), t[0] = n[0] * o, t[1] = n[1] * o, t[2] = n[2] * o), t;
}
function P(t, n) {
  return t[0] * n[0] + t[1] * n[1] + t[2] * n[2];
}
function _(t, n, a) {
  const s = n[0],
    r = n[1],
    o = n[2],
    u = a[0],
    e = a[1],
    c = a[2];
  return t[0] = r * c - o * e, t[1] = o * u - s * c, t[2] = s * e - r * u, t;
}
function A(t, n, a, s) {
  const r = n[0],
    o = n[1],
    u = n[2];
  return t[0] = r + s * (a[0] - r), t[1] = o + s * (a[1] - o), t[2] = u + s * (a[2] - u), t;
}
function D(t, n, a, s, r, o) {
  const u = o * o,
    e = u * (2 * o - 3) + 1,
    c = u * (o - 2) + o,
    i = u * (o - 1),
    h = u * (3 - 2 * o);
  return t[0] = n[0] * e + a[0] * c + s[0] * i + r[0] * h, t[1] = n[1] * e + a[1] * c + s[1] * i + r[1] * h, t[2] = n[2] * e + a[2] * c + s[2] * i + r[2] * h, t;
}
function I(t, n, a, s, r, o) {
  const u = 1 - o,
    e = u * u,
    c = o * o,
    i = e * u,
    h = 3 * o * e,
    M = 3 * c * u,
    f = c * o;
  return t[0] = n[0] * i + a[0] * h + s[0] * M + r[0] * f, t[1] = n[1] * i + a[1] * h + s[1] * M + r[1] * f, t[2] = n[2] * i + a[2] * h + s[2] * M + r[2] * f, t;
}
function L(t, n) {
  n = n || 1;
  const s = a,
    r = 2 * s() * Math.PI,
    o = 2 * s() - 1,
    u = Math.sqrt(1 - o * o) * n;
  return t[0] = Math.cos(r) * u, t[1] = Math.sin(r) * u, t[2] = o * n, t;
}
function O(t, n, a) {
  const s = n[0],
    r = n[1],
    o = n[2];
  return t[0] = a[0] * s + a[4] * r + a[8] * o + a[12], t[1] = a[1] * s + a[5] * r + a[9] * o + a[13], t[2] = a[2] * s + a[6] * r + a[10] * o + a[14], t;
}
function S(t, n, a) {
  const s = n[0],
    r = n[1],
    o = n[2];
  return t[0] = s * a[0] + r * a[3] + o * a[6], t[1] = s * a[1] + r * a[4] + o * a[7], t[2] = s * a[2] + r * a[5] + o * a[8], t;
}
function E(t, n, a) {
  const s = a[0],
    r = a[1],
    o = a[2],
    u = a[3],
    e = n[0],
    c = n[1],
    i = n[2],
    h = r * i - o * c,
    M = o * e - s * i,
    f = s * c - r * e,
    l = r * f - o * M,
    m = o * h - s * f,
    b = s * M - r * h,
    d = 2 * u;
  return t[0] = e + h * d + 2 * l, t[1] = c + M * d + 2 * m, t[2] = i + f * d + 2 * b, t;
}
function Q(t, n, a, s) {
  const r = [],
    o = [];
  return r[0] = n[0] - a[0], r[1] = n[1] - a[1], r[2] = n[2] - a[2], o[0] = r[0], o[1] = r[1] * Math.cos(s) - r[2] * Math.sin(s), o[2] = r[1] * Math.sin(s) + r[2] * Math.cos(s), t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function T(t, n, a, s) {
  const r = [],
    o = [];
  return r[0] = n[0] - a[0], r[1] = n[1] - a[1], r[2] = n[2] - a[2], o[0] = r[2] * Math.sin(s) + r[0] * Math.cos(s), o[1] = r[1], o[2] = r[2] * Math.cos(s) - r[0] * Math.sin(s), t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function k(t, n, a, s) {
  const r = [],
    o = [];
  return r[0] = n[0] - a[0], r[1] = n[1] - a[1], r[2] = n[2] - a[2], o[0] = r[0] * Math.cos(s) - r[1] * Math.sin(s), o[1] = r[0] * Math.sin(s) + r[1] * Math.cos(s), o[2] = r[2], t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function w(t, n) {
  z(B, t), z(C, n);
  const a = P(B, C);
  return a > 1 ? 0 : a < -1 ? Math.PI : Math.acos(a);
}
const B = t(),
  C = t();
function F(t) {
  return "vec3(" + t[0] + ", " + t[1] + ", " + t[2] + ")";
}
function G(t, n) {
  return t[0] === n[0] && t[1] === n[1] && t[2] === n[2];
}
function H(t, a) {
  if (t === a) return !0;
  const s = t[0],
    r = t[1],
    o = t[2],
    u = a[0],
    e = a[1],
    c = a[2],
    i = n();
  return Math.abs(s - u) <= i * Math.max(1, Math.abs(s), Math.abs(u)) && Math.abs(r - e) <= i * Math.max(1, Math.abs(r), Math.abs(e)) && Math.abs(o - c) <= i * Math.max(1, Math.abs(o), Math.abs(c));
}
function J(t, n, a) {
  const s = a[0] - n[0],
    r = a[1] - n[1],
    o = a[2] - n[2];
  let u = s * s + r * r + o * o;
  return u > 0 ? (u = 1 / Math.sqrt(u), t[0] = s * u, t[1] = r * u, t[2] = o * u, t) : (t[0] = 0, t[1] = 0, t[2] = 0, t);
}
const K = e,
  N = c,
  R = i,
  U = x,
  X = p,
  Y = s,
  Z = v,
  V = Object.freeze(Object.defineProperty({
    __proto__: null,
    abs: f,
    add: u,
    angle: w,
    bezier: I,
    ceil: h,
    copy: r,
    cross: _,
    direction: J,
    dist: U,
    distance: x,
    div: R,
    divide: i,
    dot: P,
    equals: H,
    exactEquals: G,
    floor: M,
    hermite: D,
    inverse: y,
    len: Y,
    length: s,
    lerp: A,
    max: b,
    min: m,
    mul: N,
    multiply: c,
    negate: j,
    normalize: z,
    random: L,
    rotateX: Q,
    rotateY: T,
    rotateZ: k,
    round: d,
    scale: g,
    scaleAndAdd: q,
    set: o,
    sign: l,
    sqrDist: X,
    sqrLen: Z,
    squaredDistance: p,
    squaredLength: v,
    str: F,
    sub: K,
    subtract: e,
    transformMat3: S,
    transformMat4: O,
    transformQuat: E
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { l as A, c as B, b as C, i as D, J as E, U as F, H as G, Y as H, V as I, M as J, k as K, h as L, d as M, D as N, I as O, L as P, Q, T as R, F as S, N as T, R as U, p as a, _ as b, r as c, w as d, G as e, e as f, u as g, O as h, y as i, g as j, j as k, s as l, P as m, z as n, A as o, x as p, v as q, q as r, o as s, S as t, E as u, f as v, X as w, Z as x, m as y, K as z };